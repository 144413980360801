import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Drawer, LinearProgress, IconButton, Stack } from '@mui/material';
import { Box } from '@mui/system';
import { ArrowBackIos } from '@mui/icons-material';
import DrawerHeader from '../drawer/DrawerHeader';
import { useBooleanState, useGetStepsFromInvoiceObject } from '../../hooks';
import ClickableInvoiceStatus from '../buttons/ClickableInvoiceStatus';
import { StatusTimeline } from '../timeline';

const CessionStatus = ({ invoice }) => {
  const [openDrawer, toggleDrawer] = useBooleanState();
  const [invoiceStatusData, setInvoiceStatusData] = useState(null);

  useEffect(() => {
    if (invoice) {
      const statusData = useGetStepsFromInvoiceObject(invoice);
      setInvoiceStatusData(statusData);
    }
  }, [invoice]);

  return (
    <>
      { invoiceStatusData ? (
        <>
          <ClickableInvoiceStatus
            onClick={toggleDrawer}
            status={invoice.status}
            statusSteps={invoiceStatusData}
          />
          <Drawer
            anchor="right"
            open={openDrawer}
            onClose={toggleDrawer}
            PaperProps={{
              sx: {
                width: { xl: '35%', lg: '45%', md: '55%', sm: '80%', xs: '85%' },
              },
            }}
          >
            <Box
              sx={{ padding: 4, width: '100%', height: '100%', backgroundColor: 'white' }}
            >
              <Stack direction="row" spacing={1} py={2}>
                <IconButton
                  sx={{ color: 'gray.main' }}
                  onClick={toggleDrawer}
                  aria-label="close"
                >
                  <ArrowBackIos />
                </IconButton>

                <DrawerHeader
                  title="Estado de la factura"
                  subtitle={`Factura folio ${invoice.folio}`}
                />
              </Stack>
              <StatusTimeline statusSteps={invoiceStatusData} />
            </Box>
          </Drawer>
        </>
      ) : (
        <>
          <Box sx={{ width: '50%', mr: 1, alignself: 'left' }}>
            <LinearProgress color="gray" />
          </Box>
        </>
      )}
    </>
  );
};

CessionStatus.propTypes = {
  invoice: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.number,
      status: PropTypes.string,
      offer: PropTypes.shape({
        id: PropTypes.number,
        transferCondition: PropTypes.string,
      }),
      historystatusSet: PropTypes.shape({
        id: PropTypes.number,
        status: PropTypes.string,
      }),
      lastHistoryStatus: PropTypes.shape({
        id: PropTypes.number,
        status: PropTypes.string,
      }),
    }),
  ).isRequired,
};

export default CessionStatus;
