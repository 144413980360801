import React from 'react';
import PropTypes from 'prop-types';
import { Avatar, Grid, Typography } from '@mui/material';
import { collectionPriorityColors } from '../../constants';

const CollectionPriorityCell = React.memo(({ priority }) => (
  <Grid
    container
    alignItems="center"
    justifyContent="flex-start"
    overflow="hidden"
    direction="column"
  >
    <Avatar
      sx={{
        width: 26,
        height: 26,
        bgcolor: collectionPriorityColors[priority],
      }}
    >
      <Typography variant="subtitle2">{priority || 'F'}</Typography>
    </Avatar>
  </Grid>
));

CollectionPriorityCell.propTypes = {
  priority: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]).isRequired,
};

export default CollectionPriorityCell;
