/* eslint-disable operator-linebreak */
import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@mui/material';
import { getTimeDiffText, isEmpty } from '../../helpers';
import { diffDaysToColor } from '../../constants';
import { FINISHED_STATUS } from '../../constants/invoices';

const DateWithDaysDiffCell = ({ date, useColors, status }) => {
  const diffText = FINISHED_STATUS.includes(status) ? 'Recaudada' : getTimeDiffText(moment(date));
  const textColor =
    useColors && !FINISHED_STATUS.includes(status) ? diffDaysToColor(moment(date)) : '';
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="flex-start"
      overflow="hidden"
      direction="column"
    >
      {!isEmpty(date) && moment(date).isValid() ? (
        <>
          <Typography variant="body1">{moment(date).format('DD-MM-YYYY')}</Typography>
          <Typography variant="caption" sx={{ color: textColor }}>
            {diffText}
          </Typography>
        </>
      ) : (
        <Typography variant="body1">Sin fecha</Typography>
      )}
    </Grid>
  );
};

DateWithDaysDiffCell.propTypes = {
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(moment)]),
  useColors: PropTypes.bool,
  status: PropTypes.string,
};

DateWithDaysDiffCell.defaultProps = {
  useColors: false,
  status: '',
  date: null,
};

export default DateWithDaysDiffCell;
