import { Stack, TextField, Typography } from '@mui/material';
import { useGridApiContext } from '@mui/x-data-grid';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { getTimeDiffText } from '../../helpers';
import DatePickerActionDates from '../datePickers/DatePickerActionDates';

const GridEditDateCell = ({
  field,
  value,
  row,
  onChange,
  onChangeAll,
  debtorAveragePaymentDays,
  minDate,
  maxDate,
  disabled,
}) => {
  const apiRef = useGridApiContext();
  const changeRowValue = useCallback((changedRow, newValue) => {
    apiRef.current.setEditCellValue({ id: changedRow.id, field, value: newValue });
    apiRef.current.commitCellChange({ id: changedRow.id, field });
    apiRef.current.setCellMode(changedRow.id, field, 'view');
    apiRef.current.selectRow(changedRow.id);
    onChange(changedRow, newValue);
  }, [apiRef, field, onChange]);
  const shouldDisableWeekend = (date) => date.day() === 0 || date.day() === 6;
  const formatValue = useCallback((momentDate) => {
    if (momentDate) {
      return momentDate ? momentDate.format('DD/MM/YYYY') : '';
    }
    return '';
  }, []);

  const handleChange = useCallback((newValue) => {
    const formatedValue = formatValue(newValue);
    const dateRegex = /^\d{2}\/\d{2}\/\d{4}$/;
    if (dateRegex.test(formatedValue)) {
      const minDateStartOfDay = minDate.startOf('day');
      const momentDate = moment(formatedValue, 'DD-MM-YYYY');
      if (
        momentDate.isValid()
        && !shouldDisableWeekend(momentDate)
        && minDateStartOfDay <= momentDate
        && momentDate <= maxDate) {
        changeRowValue(row, newValue);
      }
    } else {
      changeRowValue(row, '');
    }
  }, [changeRowValue, row]);

  const closeDatePicker = useCallback(() => {
    apiRef.current.getCellElement(row.id, field).click();
  }, [apiRef]);

  const defaultOnChangeAll = useCallback((newValue) => {
    const allRows = apiRef.current.getAllRowIds();
    allRows.forEach((rowId) => {
      const newRow = apiRef.current.getRow(rowId); // Obtener cada fila
      changeRowValue(newRow, newValue); // Cambiar el valor de cada fila
    });
    closeDatePicker();
  }, [apiRef, changeRowValue]);
  return (
    <>
      <DatePicker
        disabled={disabled}
        value={value || ''}
        renderInput={(params) => (
          <Stack>
            <TextField
              {...params}
              inputProps={{
                ...params.inputProps,
                style: { padding: '0px 0px', textAlign: 'center' },
                'data-hj-allow': '',
                'data-hj-datepicker': '',
              }}
              // eslint-disable-next-line react/jsx-no-duplicate-props
              InputProps={{ ...params.InputProps, sx: { fontSize: { xs: 10, md: 12 } } }}
            />
            <Typography variant="caption" sx={{ opacity: value ? 1 : 0 }}>
              {value ? getTimeDiffText(value) : 'No se ve este mensaje'}
            </Typography>
          </Stack>
        )}
        onChange={handleChange}
        closeOnSelect={false}
        minDate={minDate}
        maxDate={maxDate}
        components={{
          ActionBar: DatePickerActionDates,
        }}
        componentsProps={{
          actionBar: {
            onAllActions: onChangeAll
              ? () => onChangeAll(value)
              : () => defaultOnChangeAll(value),
            closeDatePicker,
            handleChange,
            debtorAveragePaymentDays,
            disableWeekend: true,
            buttons: [
              {
                days: 30,
                label: '30 días',
              },
              {
                days: 45,
                label: '45 días',
              },
              {
                days: 60,
                label: '60 días',
              },
            ],
          },
        }}
        PopperProps={{
          placement: 'left-start',
        }}
        shouldDisableDate={shouldDisableWeekend}
      />
    </>
  );
};

GridEditDateCell.propTypes = {
  debtorAveragePaymentDays: PropTypes.number,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  field: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.instanceOf(moment), PropTypes.string]),
  row: PropTypes.shape({ id: PropTypes.string.isRequired }).isRequired,
  onChange: PropTypes.func.isRequired,
  onChangeAll: PropTypes.func,
  minDate: PropTypes.instanceOf(moment),
  maxDate: PropTypes.instanceOf(moment),
  disabled: PropTypes.bool,
};

GridEditDateCell.defaultProps = {
  debtorAveragePaymentDays: null,
  value: '',
  onChangeAll: null,
  minDate: moment().subtract(180, 'days'),
  maxDate: moment().add(150, 'days'),
  disabled: false,
};

export default GridEditDateCell;
