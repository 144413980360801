/* eslint-disable max-len */
import {
  AccessTime,
  CheckCircleOutline,
  ErrorOutline,
  HelpOutline,
  RadioButtonUnchecked,
} from '@mui/icons-material';
import { Step, StepLabel, Stepper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { statusToActiveStep, statusToStepLabels } from '../../helpers';
import { useIsMobile } from '../../hooks';

export const statusToIcon = {
  PROCESSING: <AccessTime color="warning" />,
  REJECTED: <ErrorOutline color="error" />,
  PENDINGRATIFICATION: <AccessTime color="warning" />,
  PENDINGDOCUMENTSREVIEW: <AccessTime color="warning" />,
  REJECTEDRATIFICATION: <ErrorOutline color="error" />,
  PENDINGTRANSFER: <AccessTime color="warning" />,
  REJECTEDTRANSFER: <ErrorOutline color="error" />,
  TRANSFERED: <AccessTime color="warning" />,
  DEBT: <ErrorOutline color="error" />,
  FINISHED: <CheckCircleOutline color="success" />,
  INFORMATION: <HelpOutline style={{ color: '#808080' }} />,
  EXPIRED: <ErrorOutline color="warning" />,
};

export const statusToColor = {
  PROCESSING: 'warning',
  REJECTED: 'error',
  PENDINGRATIFICATION: 'warning',
  PENDINGDOCUMENTSREVIEW: 'warning',
  REJECTEDRATIFICATION: 'error',
  PENDINGTRANSFER: 'warning',
  REJECTEDTRANSFER: 'error',
  TRANSFERED: 'warning',
  DEBT: 'error',
  FINISHED: 'success',
  INFORMATION: '#808080',
  RECEDED: '#808080',
  EXPIRED: 'warning',
};

const useStyles = makeStyles({
  stepLabel: {
    marginTop: '4px !important',
    fontSize: 12,
    whiteSpace: 'nowrap',
  },
});

const getStatusIcon = (status, i) => {
  if (i < statusToActiveStep[status]) return <CheckCircleOutline color="success" />;
  if (statusToActiveStep[status] === i) return statusToIcon[status];
  return <RadioButtonUnchecked />;
};

export const Status = ({ status, paymentDate, oneStepper }) => {
  const classes = useStyles();
  const isMobile = useIsMobile();

  return (
    <Stepper sx={{ p: 0, width: '100%' }} activeStep={-1} alternativeLabel>
      {statusToStepLabels(status, oneStepper).map(
        (label, i) => (((isMobile || oneStepper) && i === statusToActiveStep[status]) || !(isMobile || oneStepper)) && (
          <Step key={label}>
            <StepLabel
              icon={getStatusIcon(status, i)}
              classes={{ label: classes.stepLabel }}
            >
              {label}
              <br />
              {status === 'FINISHED'
                && statusToActiveStep[status] === i
                && paymentDate
                && moment(paymentDate).format('DD-MM-YYYY')}
            </StepLabel>
          </Step>
        ),
      )}
    </Stepper>
  );
};

Status.propTypes = {
  status: PropTypes.oneOf([
    'CREATED',
    'PROCESSING',
    'PENDINGDOCUMENTSREVIEW',
    'REJECTED',
    'PENDINGRATIFICATION',
    'REJECTEDRATIFICATION',
    'PENDINGTRANSFER',
    'REJECTEDTRANSFER',
    'TRANSFERED',
    'DEBT',
    'FINISHED',
    'SURPLUSWITHDRAWN',
    'BADDEBT',
    'LOANEDOTHER',
    'RECEDED',
    'EXPIRED',
  ]).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  paymentDate: PropTypes.object,
  oneStepper: PropTypes.bool,
};

Status.defaultProps = {
  paymentDate: null,
  oneStepper: false,
};
