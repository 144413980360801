import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Avatar, Grid, Tooltip } from '@mui/material';
import { userHasRoutePermission } from '../../helpers/routes';
import AssignNewExecutiveCell from './AssignNewExecutiveCell';
import { useGetUser } from '../../hooks';
import { FilePropType } from '../../propTypes/FilePropType';

const AvatarExecutiveAssignedCell = ({ companyId, user, assignationType, ...extraProps }) => {
  const permissionType = {
    ratification: 'debtors.change_debtor',
    collection: 'debtors.change_debtor',
    executive: 'customers.change_company',
  }[assignationType];
  const currentUser = useGetUser();
  const showAssign = useMemo(() => userHasRoutePermission(currentUser, permissionType), []);
  const componentToShow = useMemo(() => {
    if (showAssign) {
      return (
        <AssignNewExecutiveCell
          companyId={companyId}
          user={user}
          assignationType={assignationType}
          {...extraProps}
        />
      );
    }
    return (
      <Avatar
        alt={user?.firstName || ''}
        src={user?.picture?.url || ''}
        {...extraProps}
      />
    );
  }, [user]);
  return (
    <Tooltip
      arrow
      placement="top"
      title={user ? user.completeName : 'Sin asignar'}
    >
      <Grid container alignItems="center" justifyContent="left">
        {componentToShow}
      </Grid>
    </Tooltip>
  );
};

AvatarExecutiveAssignedCell.propTypes = {
  companyId: PropTypes.string,
  assignationType: PropTypes.oneOf(['ratification', 'collection', 'executive']).isRequired,
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    completeName: PropTypes.string.isRequired,
    picture: FilePropType,
  }),
};

AvatarExecutiveAssignedCell.defaultProps = {
  companyId: null,
  user: undefined,
};

export default AvatarExecutiveAssignedCell;
