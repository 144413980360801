import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Stack, Typography } from '@mui/material';
import moment from 'moment';
import { HtmlTooltip } from '../tooltips';

const BaseStatusSummaryStepperPremium = ({ invoice, siiStatusMaster,
  steps, CustomIconStepper }) => {
  const { receptionDate, siiStatus, uniqueDocumentFinanceState } = invoice;
  const lastApiState = uniqueDocumentFinanceState.length
    ? uniqueDocumentFinanceState.reduce((prev, current) => ((
      moment(current.eventTime) > moment(prev.eventTime)) ? current : prev)) : {};
  const showComment = useCallback(() => {
    if (lastApiState.rejectionCode?.reason) {
      return lastApiState.rejectionCode.reason;
    }
    if (lastApiState.status?.code === '7') {
      const payDate = lastApiState.paymentDate?.format('DD-MM-YYYY') || '';
      return `${lastApiState.status.status}\n${payDate}`;
    }
    if (lastApiState.status) {
      return lastApiState.status.status;
    }
    return siiStatusMaster(invoice)[siiStatus].label;
  }, [lastApiState, receptionDate, siiStatus]);
  const tooltipText = ({ uniqueDocumentFinanceState: state }) => {
    if (state.length) {
      return `Comentarios:\n${state[0]?.comments || 'Sin Comentarios'}`;
    }
    return 'Tu deudor no tiene portal de proveedores con nosotros';
  };
  return (
    <HtmlTooltip
      title={(
        <Typography color="inherit" sx={{ whiteSpace: 'pre-line' }}>
          {tooltipText(invoice)}
        </Typography>
      )}
    >
      <Stack direction="column" textAlign="center" alignItems="center">
        <Stack direction="row" spacing="8px">
          {
            steps.map((step) => (
              <CustomIconStepper
                key={step}
                current={step}
                receptionDate={receptionDate}
                siiStatus={siiStatus}
                lastApiState={lastApiState}
                invoice={invoice}
              />
            ))
          }
        </Stack>
        <Typography sx={{ fontSize: '10px', whiteSpace: 'pre-line' }}>
          {showComment()}
        </Typography>
      </Stack>
    </HtmlTooltip>
  );
};

BaseStatusSummaryStepperPremium.propTypes = {
  invoice: PropTypes.shape({
    siiStatus: PropTypes.string.isRequired,
    uniqueDocumentFinanceState: PropTypes.array, // eslint-disable-line react/forbid-prop-types
    receptionDate: PropTypes.objectOf(moment).isRequired,
    receiver: PropTypes.shape({
      rut: PropTypes.string,
    }),
  }).isRequired,
  siiStatusMaster: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.string).isRequired,
  CustomIconStepper: PropTypes.func.isRequired,
};

export default BaseStatusSummaryStepperPremium;
