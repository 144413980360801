import { Avatar, Grid, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { FilePropType } from '../../propTypes/FilePropType';

const AvatarCell = ({ user, align, ...extraProps }) => {
  const component = useMemo(() => (
    <Tooltip
      arrow
      placement="top"
      title={user ? (
        `${user.firstName} ${user.lastName || ''}`
      ) : (
        'Sin asignar'
      )}
    >
      <Grid container alignItems="center" justifyContent={align}>
        <Avatar
          alt={user?.firstName || ''}
          src={user?.picture?.url || ''}
          {...extraProps}
        />
      </Grid>
    </Tooltip>
  ), [user, align]);
  return component;
};

AvatarCell.propTypes = {
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    picture: FilePropType,
  }),
  align: PropTypes.string,
};

AvatarCell.defaultProps = {
  user: {
    firstName: 'Sin asignar',
    lastName: '',
    picture: null,
  },
  align: 'center',
};

export default AvatarCell;
