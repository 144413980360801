import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { GridRowModes, GridToolbarContainer } from '@mui/x-data-grid';
import { randomId } from '@mui/x-data-grid-generator';

const DataGridToolbar = ({ setRows, setRowModesModel, addLabel, columns }) => {
  const handleClick = () => {
    const id = randomId();
    const columnFields = columns.reduce(
      (obj, col) => Object.assign(obj, { [col.field]: '' }),
      {},
    );
    setRows((oldRows) => [...oldRows, { id, ...columnFields, isNew: true }]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: columns[0].field },
    }));
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        {addLabel}
      </Button>
    </GridToolbarContainer>
  );
};

DataGridToolbar.propTypes = {
  setRowModesModel: PropTypes.func.isRequired,
  setRows: PropTypes.func.isRequired,
  columns: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  addLabel: PropTypes.string,
};

DataGridToolbar.defaultProps = {
  addLabel: 'Añadir nuevo',
};

export default DataGridToolbar;
